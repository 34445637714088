export const links = [
  {
    label: 'Home',
    slug: '/',
  },
  {
    label: 'Sobre',
    slug: '/sobre',
  },
  {
    label: 'Busca',
    slug: '/busca',
  },
  {
    label: 'Contato',
    slug: '/contato',
  },
];
